import { createApp } from 'vue'
import store from './store'
import ElementPlus from 'element-plus'

import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import Cookie from 'js-cookie'

import ECharts from 'vue-echarts';
import 'echarts/lib/chart/line';  // 根据需要引入ECharts的图表类型
import 'echarts/lib/component/tooltip';  // 根据需要引入ECharts的组件

const app = createApp(App)
app.use(ECharts)
// 注册组件 
app.component('v-chart', ECharts)
app.use(store)
app.use(router)
app.use(Cookie)
app.use(ElementPlus, {
    locale: zhCn,
})
app.mount('#app')