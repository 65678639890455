import {
    createRouter,
    createWebHistory
} from "vue-router";
const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    redirect: '/Dashboard',
    children: [{
        path: '/Manage',
        name: 'Manage',
        component: () => import('../views/Manage.vue')
    },
    {
        path: '/OwnerManage',
        name: 'OwnerManage',
        component: () => import('../views/OwnerManage.vue')
    },
    {
        path: '/HouseManage',
        name: 'HouseManage',
        component: () => import('../views/HouseManage.vue')
    },
    {
        path: '/TerminalManage',
        name: 'TerminalManage',
        component: () => import('../views/TerminalManage.vue')
    },
    {
        path: '/Dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/UserOfHous',
        name: 'UserOfHous',
        component: () => import('../views/UserOfHous.vue')
    },
    {
        path: '/StatusManage',
        name: 'StatusManage',
        component: () => import('../views/StatusManage.vue')
    },
    {
        path: '/HitchManage',
        name: 'HitchManage',
        component: () => import('../views/HitchManage.vue')
    },

    ]
},
{
    path: '/login',
    name: 'login',
    component: () => import('../views/MLogin.vue')
},
]
const router = createRouter({
    history: createWebHistory(process.env.Base_URL),
    routes
})

export default router