import {
    createStore
} from 'vuex'
export default createStore({
    state: {
        auth: {},
        themeColor:'#189B9C'
    },
    getters: {
    },
    mutations: {
        setAuth(state) {
            state.auth = {
                role_id: '1',
                user_id: '4594',
                role_name: '生命科技园'
            },
            state.themeColor = '#012373'
        }
    },
})